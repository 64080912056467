// Navbar
const navbar = document.querySelector(".navbar");
const logo = document.getElementById("navbar__logo");
const nav_instagram = document.getElementById("navbar__instagram");
const nav_youtube = document.getElementById("navbar__youtube");
const nav_whatsapp = document.getElementById("navbar__whatsapp");

// Navbar scroll change

window.onscroll = function () {
  // pageYOffset or scrollY
  if (window.pageYOffset > 0) {
    navbar.classList.add("navbar--active");
    logo.src = "./assets/logo.png";
    nav_instagram.src = "./assets/instagram-dark.png";
    nav_youtube.src = "./assets/youtube-dark.png";
    nav_whatsapp.src = "./assets/linkendin-dark.png";
  } else {
    navbar.classList.remove("navbar--active");
    logo.src = "./assets/logo-white.png";
    nav_instagram.src = "./assets/instagram-light.png";
    nav_youtube.src = "./assets/youtube-light.png";
    nav_whatsapp.src = "./assets/linkendin-light.png";
  }
};

// Accordion Menu
const accordion = document.querySelectorAll(".services__accordion-item");

accordion.forEach((accordion) => {
  accordion.addEventListener("click", (event) => {
    accordion.classList.toggle("accordion--active");
    arrow.classList.toggle("arrow--active");
  });
});

// Image Slider

let slideIndex = 0;
showSlides();

// Next-previous control
function nextSlide() {
  slideIndex++;
  showSlides();
  timer = _timer; // reset timer
}

function prevSlide() {
  slideIndex--;
  showSlides();
  timer = _timer;
}

// Thumbnail image controls
function currentSlide(n) {
  slideIndex = n - 1;
  showSlides();
  timer = _timer;
}

function showSlides() {
  let slides = document.querySelectorAll(".content__slide");
  let dots = document.querySelectorAll(".content__dot");

  if (slideIndex > slides.length - 1) slideIndex = 0;
  if (slideIndex < 0) slideIndex = slides.length - 1;

  // hide all slides
  slides.forEach((slide) => {
    slide.style.display = "none";
  });

  // show one slide base on index number
  slides[slideIndex].style.display = "block";

  dots.forEach((dot) => {
    dot.classList.remove("dots-active");
  });
  dots[slideIndex].classList.add("dots-active");
}

// autoplay slides --------
let timer = 7; // sec
const _timer = timer;

// this function runs every 1 second
setInterval(() => {
  timer--;

  if (timer < 1) {
    nextSlide();
    timer = _timer; // reset timer
  }
}, 1000); // 1sec

// Hamburger Menu

const burger = document.querySelector(".navbar__burger");
const menu = document.querySelector(".navbar__menu");

burger.addEventListener("click", () => {
  menu.classList.toggle("menu--active");
  burger.classList.toggle("toggle");
});

// Scroll Animations

function animation() {
  var controller = new ScrollMagic.Controller();

  // Landing Animation

  const t1 = gsap.timeline({ defaults: { ease: Expo.InOut } });

  t1.fromTo(navbar, 0.7, { y: "-10rem" }, { y: 0 });
  t1.fromTo(".hero__content", 0.7, { y: "-10rem" }, { y: 0 }, "-=0.7");

  // Content Scroll Animation

  const t2 = gsap.timeline({ defaults: { ease: Expo.InOut } });

  t2.fromTo(
    ".content__left ",
    0.7,
    { x: "-3rem", opacity: 0 },
    { x: 0, opacity: 1 }
  );

  new ScrollMagic.Scene({
    triggerElement: "#content",
    triggerHook: 0.5,
    reverse: false,
  })
    .setTween(t2)
    .addTo(controller);

  // About Scroll Animation

  const t3 = gsap.timeline({ defaults: { ease: Expo.InOut } });

  t3.fromTo(
    ".about__container ",
    0.7,
    { x: "-3rem", opacity: 0 },
    { x: 0, opacity: 1 }
  );

  new ScrollMagic.Scene({
    triggerElement: "#about",
    triggerHook: 0.5,
    reverse: false,
  })
    .setTween(t3)
    .addTo(controller);

  // Services Cards Scroll Animation

  const t4 = gsap.timeline({ defaults: { ease: Expo.InOut } });

  t4.fromTo(
    ".services__card",
    0.7,
    { y: "-3rem", opacity: 0 },
    { y: 0, opacity: 1, stagger: 0.3 }
  );

  t4.set(".services__card", { clearProps: "all" });

  t4.fromTo(
    ".services__content",
    0.7,
    { x: "-3rem", opacity: 0 },
    { x: 0, opacity: 1 },
    "-=0.4"
  );

  new ScrollMagic.Scene({
    triggerElement: "#services",
    triggerHook: 0.5,
    reverse: false,
  })
    .setTween(t4)
    .addTo(controller);

  // Contact Scroll Animation

  const t5 = gsap.timeline({ defaults: { ease: Expo.InOut } });
  t5.fromTo(
    ".contact__info",
    1,
    { x: "-3rem", opacity: 0 },
    { x: 0, opacity: 1 }
  );

  new ScrollMagic.Scene({
    triggerElement: "#contact",
    triggerHook: 0.5,
    reverse: false,
  })
    .setTween(t5)
    .addTo(controller);
}

animation();
